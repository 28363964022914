<template>
  <div class="app-container">
    <eHeader ref="header" :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="siteName" label="所属社区"/>
      <el-table-column prop="userId" label="客户ID"/>
      <el-table-column prop="userName" label="客户名称"/>
<!--      <el-table-column prop="login" label="MT4账户"/>-->
      <el-table-column prop="title" label="推荐标题"/>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status==1? '有效':'无效'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','RECOMMEND_ALL','RECOMMEND_EDIT'])" :data="scope.row" :sup_this="sup_this"/>
          <el-popover
            v-if="checkPermission(['ADMIN','RECOMMEND_ALL','RECOMMEND_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del } from '@/api/community/srRecommendedToday'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/community/recommend/header'
import edit from '@/components/community/recommend/edit'
export default {
  name:'recommend',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'community/crm/srRecommendedToday'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const siteId = query.siteId
      const title = query.title
      const userName = query.userName
      const login = query.login
      const status = query.status
      if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
      }
      if (title!=null && title!='') { this.params['title'] = title }
      if (userName!=null && userName!='') { this.params['userName'] = userName }
      if (login!=null && login!='') { this.params['login'] = login }
      if (status!=null && status!='') { this.params['status'] = status }
      return true
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
