<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        siteId: this.data.siteId,
        backimg: this.data.backimg,
        title: this.data.title,
        titleEn: this.data.titleEn,
        userId: this.data.userId,
        userName: this.data.userName,
        login: this.data.login,
        loginOrder: this.data.loginOrder,
        cooperationStory: this.data.cooperationStory,
        cooperationStoryEn: this.data.cooperationStoryEn,
        status: this.data.status+'',
        serverId:this.data.serverId,
        remark: this.data.remark,
        index1: this.data.index1,
        index2: this.data.index2,
        index3: this.data.index3,
        mt4accountId:this.data.mt4accountId
      }
      _this.customerList = [{
        userId: this.data.userId,
        userName: this.data.userName,
        login: this.data.login,
        loginOrder: this.data.loginOrder,
        serverId:this.data.serverId,
        mt4accountId:this.data.mt4accountId
      }]
      _this.selectCustomer = {
        userId: this.data.userId,
        userName: this.data.userName,
        login: this.data.login,
        loginOrder: this.data.loginOrder,
        serverId:this.data.serverId,
        mt4accountId:this.data.mt4accountId
      }
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
