<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="700px">
    <el-form ref="form" :model="form"  size="small" :rules="rules" label-width="120px">
      <el-form-item label="所属社区">
        <el-select
          v-model="form.siteId"
          placeholder="所属站点"
          style="width: 500px;"
        >
          <el-option
            v-for="item in siteList"
            :key="item.id"
            :label="item.siteName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item  label="中文标题" prop="title">
        <el-input
          v-model="form.title"
          style="width: 500px;"
          maxlength="6"
          show-word-limit
        />
      </el-form-item>
      <el-form-item  label="英文标题" prop="titleEn">
        <el-input
          v-model="form.titleEn"
          style="width: 500px;"
          maxlength="12"
          show-word-limit
        />
      </el-form-item>
      <el-form-item v-if="!isSmipleCommunity" label="Banner图">
        <el-upload
          ref="upload"
          class="upload"
          :action="uploadUrl"
          :on-success="handleSuccess"
          multiple
          method:="post"
          :show-file-list="false"
          :file-list="fileLists">
          <el-button size="small" type="primary" plain>选择文件</el-button>
        </el-upload>
        <img v-if="form.backimg!=null&&form.backimg!=''" :src="form.backimg" alt="" style="width:500px;height:200px;">
        <el-input type="textarea" autosize v-model="form.backimg" style="width: 500px;"/>
      </el-form-item>
       <el-form-item label="中文介绍" prop="cooperationStory">
        <el-input
          type="textarea"
          autosize
          v-model="form.cooperationStory"
          style="width: 500px;"
          maxlength="40"
          show-word-limit
        />
      </el-form-item>
      <el-form-item
        label="英文介绍"
        prop="cooperationStoryEn"
      >
        <el-input
          type="textarea"
          autosize
          v-model="form.cooperationStoryEn"
          style="width: 500px;"
          maxlength="80"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="状态">
         <el-radio v-model="form.status" label= 1>有效</el-radio>
         <el-radio v-model="form.status" label= 2>无效</el-radio>
      </el-form-item>
      <el-form-item label="选择客户">
        <div>
          <el-input v-model="query" clearable placeholder="输入客户ID、姓名或MT4" style="width: 400px;"  @keyup.enter.native="toQuery"/>
          <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
        </div>
        <el-table
          ref="singleTable"
          :data="customerList"
          highlight-current-row
          @current-change="handleCurrentChange"
          style="width: 500px;max-height:400px;">
          <el-table-column property="loginOrder" label="MT4序号"> </el-table-column>
<!--          <el-table-column property="login" label="MT4"> </el-table-column>-->
          <el-table-column property="userId" label="客户ID"> </el-table-column>
          <el-table-column property="userName" label="客户名称"> </el-table-column>
        </el-table>
      </el-form-item>
      <!-- <el-form-item label="展示指标">
        <el-transfer
          style="width: 500px"
          :titles="['可选指标', '已选指标']"
          v-model="form.indexList"
          :data="indexList">
        </el-transfer>
      </el-form-item>   -->
      <el-form-item label="展示指标">
        <el-select v-model="form.index1" placeholder="选择指标" style="width: 150px;">
          <el-option
            v-for="item in indexList"
            :key="item.indexCode"
            :label="item.indexName"
            :value="item.indexCode">
          </el-option>
        </el-select>
        <el-select v-model="form.index2" placeholder="选择指标" style="width: 150px;">
          <el-option
            v-for="item in indexList"
            :key="item.indexCode"
            :label="item.indexName"
            :value="item.indexCode">
          </el-option>
        </el-select>
        <el-select v-model="form.index3" placeholder="选择指标" style="width: 150px;">
          <el-option
            v-for="item in indexList"
            :key="item.indexCode"
            :label="item.indexName"
            :value="item.indexCode">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" style="width: 500px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit ,selectRecommendedTodays} from '@/api/community/srRecommendedToday'
import { getByteLen } from '@/utils/validate'
import { initData } from '@/api/data'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  created() {
    this.initSiteList()
    this.initIndexList()
  },
  computed:{
    isSmipleCommunity(){
      let flag = false;
      for (let i = 0; i < this.siteList.length; i++) {
        if(this.siteList[i].id == this.form.siteId){
          if(this.siteList[i].siteType == 3){
            flag = true;
            break;
          }
        }
      }
      return flag;
    },
  },
  data() {
    let validatetTitle = (rule,value,callback) => {
      console.log(value);
        if(!value){
          callback(new Error('请输入推荐标题'));
        }
        if (this.isSmipleCommunity&&this.getByteLen(value)>12){
          callback(new Error('投资版社区推荐标题最多只能输入12个字符(6个汉字)！'));
        }else if(!this.isSmipleCommunity&&this.getByteLen(value)>56) {
          callback(new Error('综合版社区推荐标题最多只能输入56个字符(28个汉字)！'));
        }else {
          callback();
        }

      };
      let validateCooperationStory = (rule,value,callback) => {
        if(!value){
          console.log(value);
          callback(new Error('请输入推荐介绍'));
        }
        if (this.getByteLen(value)>80){
          callback(new Error('推荐介绍最多只能输入80个字符(40个汉字)！'));
        }else {
          callback();
        }
      };
    return {
      loading: false,
      dialog: false,
      query:null,
      customerList:[],
      selectCustomer:null,
      fileLists:[],
      uploadUrl:process.env.VUE_APP_API+'community/auth/file/upload',
      siteList:[],
      indexList:[],
      selectSite:{},
      rules:{
        title:[
          // { validator: validatetTitle, trigger: 'blur' },
          { required:true, message: '请输入中文标题', trigger: 'blur' },
        ],
        titleEn:[
          // { validator: validatetTitle, trigger: 'blur' },
          { required:true, message: '请输入英文标题', trigger: 'blur' },
        ],
        cooperationStory:[
          // { validator: validateCooperationStory, trigger: 'blur' },
          { required:true, message: '请输入中文介绍', trigger: 'blur' },
        ],
        cooperationStoryEn:[
          // { validator: validateCooperationStory, trigger: 'blur' },
          { required:true, message: '请输入英文介绍', trigger: 'blur' },
        ],
      },

      form: {
        id: '',
        siteId: 1,
        backimg: '',
        title: '',
        titleEn: '',
        userId: '',
        userName: '',
        login: '',
        loginOrder: '',
        cooperationStory: '',
        cooperationStoryEn: '',
        index1:'',
        index2:'',
        index3:'',
        // indexList:[],
        status: '1',
        serverId:'',
        remark: '',
        mt4accountId:''
      }
    }
  },
  methods: {
    getByteLen,
    cancel() {
      this.resetForm()
    },
    initSiteList(){
      const url = 'cms/crm/srSite'
      const sort = 'sortNo,asc'
      const params = {sort: sort,page:0,size:1000}
      initData(url, params).then(res => {
          this.siteList = res.content
          this.form.siteId = this.siteList[0].id
        }).catch(err => {
          reject(err)
        })
    },
    initIndexList(){
      const url = 'community/crm/srSignalIndexList'
      const params = {}
      initData(url, params).then(res => {
          // let data = []
          // res.content.forEach((item, index) => {
          //   data.push({
          //     label: item.indexName,
          //     key: item.indexCode,
          //   });
          // });
          this.indexList=  res.content
        }).catch(err => {
          reject(err)
        })
    },
    handleSuccess(response){
        //上传成功要处理的事
        this.form.backimg=response.data.content[0].url;
    },
    handleCurrentChange(val) {
      this.selectCustomer = val;
      console.log(this.selectCustomer,'selectCustomer')
    },
    toQuery() {
      if(this.query==null) {
         this.$message.error('请输入查询条件');
         return
      }
      const params = {userInfo:this.query}
      selectRecommendedTodays(params).then(res => {
        this.customerList = res;
      })
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          // if (this.isSmipleCommunity&&this.getByteLen(this.form.title)>12){
          //   this.$message.error('投资版社区推荐标题最多只能输入12个字符！');
          //   return
          // }else if(!this.isSmipleCommunity&&this.getByteLen(this.form.title)>56) {
          //   this.$message.error('综合版社区推荐标题最多只能输入56个字符！');
          //   return
          // }else if(this.getByteLen(this.form.cooperationStory)>80) {
          //   this.$message.error('推荐介绍最多只能输入80个字符！');
          //   return
          // }else if(this.selectCustomer==null) {
          //   this.$message.error('请选择客户');
          //   return
          // }
          this.form.userId = this.selectCustomer.userId
          this.form.userName = this.selectCustomer.userName
          this.form.login = this.selectCustomer.login
          this.form.loginOrder = this.selectCustomer.loginOrder
          this.form.serverId = this.selectCustomer.serverId
          this.form.mt4accountId = this.selectCustomer.mt4accountId
          // if(this.form.indexList==null || this.form.indexList.length==0){
          //    this.$message.error('请选择展示指标');
          //    return
          // }else {

          // }
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else this.doEdit()
        }
      })
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.selectCustomer=null
      this.customerList = []
      this.form = {
        id: '',
        siteId: 1,
        backimg: '',
        title: '',
        userId: '',
        userName: '',
        login: '',
        loginOrder: '',
        cooperationStory: '',
        // indexList:[],
        index1:'',
        index2:'',
        index3:'',
        status: '1',
        serverId:'',
        remark: '',
        mt4accountId:''
      }
    }
  }
}
</script>

<style scoped>

</style>
